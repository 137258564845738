import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "div",
        [
          _c(
            VBtn,
            {
              attrs: {
                color: "primary",
                rounded: "",
                fab: "",
                absolute: "",
                top: "",
                right: "",
              },
              on: {
                click: function ($event) {
                  return _vm.add_news_dialog()
                },
              },
            },
            [_c(VIcon, { attrs: { large: "" } }, [_vm._v("mdi-plus")])],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-5" },
            [
              _c("SelectBriefingLanguageComponent", {
                on: {
                  handleChangeLanguage: function (e) {
                    return (_vm.selectedLanguage = e)
                  },
                },
              }),
            ],
            1
          ),
          _c(VSimpleTable, [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("title")))]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("edit"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("remove"))),
                ]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(
                _vm.company_news.filter(function (x) {
                  return _vm.gs.getLanguage(x.Language) === _vm.selectedLanguage
                }),
                function (news_obj, index) {
                  return _c("tr", { key: index }, [
                    _c("td", [_vm._v(_vm._s(news_obj.Title))]),
                    _c(
                      "td",
                      { staticClass: "text-center" },
                      [
                        _c(
                          VBtn,
                          {
                            staticClass: "px-2 ml-1 secondary",
                            attrs: { "min-width": "0", small: "" },
                            on: {
                              click: function ($event) {
                                return _vm.edit_news_dialog(news_obj)
                              },
                            },
                          },
                          [
                            _c(VIcon, { attrs: { small: "" } }, [
                              _vm._v("mdi-pencil"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      { staticClass: "text-center" },
                      [
                        _c(
                          VBtn,
                          {
                            staticClass: "px-2 ml-1",
                            attrs: {
                              color: "red",
                              "min-width": "0",
                              small: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.open_delete_dialog(news_obj)
                              },
                            },
                          },
                          [
                            _c(VIcon, { attrs: { small: "" } }, [
                              _vm._v("mdi-trash-can"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                }
              ),
              0
            ),
          ]),
          _vm.dialog
            ? _c(
                VDialog,
                {
                  model: {
                    value: _vm.dialog,
                    callback: function ($$v) {
                      _vm.dialog = $$v
                    },
                    expression: "dialog",
                  },
                },
                [
                  _c(
                    VCard,
                    [
                      _c(VCardTitle, [
                        _c("h4", { staticClass: "dxa_modal_title h4" }, [
                          _vm._v(_vm._s(_vm.$t("manage_news"))),
                        ]),
                      ]),
                      !_vm.dialog_loading
                        ? _c(
                            VCardText,
                            [
                              _c(
                                VForm,
                                {
                                  ref: "form",
                                  staticClass: "mt-5",
                                  on: {
                                    submit: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.checkForm()
                                    },
                                  },
                                },
                                [
                                  _c(VSelect, {
                                    attrs: {
                                      items: _vm.index_options,
                                      label: _vm.$t("index"),
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.news.Index,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.news, "Index", $$v)
                                      },
                                      expression: "news.Index",
                                    },
                                  }),
                                  _c(
                                    VRow,
                                    [
                                      _c(
                                        VCol,
                                        { attrs: { cols: "12", md: "3" } },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "text-center" },
                                            [
                                              _c(
                                                VAvatar,
                                                {
                                                  staticClass: "newsImage",
                                                  attrs: {
                                                    tile: "",
                                                    color: "secondary",
                                                  },
                                                  on: {
                                                    click: _vm.openFilePicker,
                                                  },
                                                },
                                                [
                                                  _vm.news.Base64Image != null
                                                    ? _c(VImg, {
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                        },
                                                        attrs: {
                                                          src: _vm.news
                                                            .Base64Image,
                                                        },
                                                      })
                                                    : _vm.news.Image != null
                                                    ? _c(VImg, {
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                        },
                                                        attrs: {
                                                          src: _vm.gs.get_photo_path(
                                                            _vm.news.Image
                                                          ),
                                                        },
                                                      })
                                                    : _c(
                                                        VIcon,
                                                        {
                                                          attrs: {
                                                            dark: "",
                                                            "x-large": "",
                                                          },
                                                        },
                                                        [_vm._v("mdi-image")]
                                                      ),
                                                  _c("input", {
                                                    ref: "imageNewsInput",
                                                    staticStyle: {
                                                      display: "none",
                                                    },
                                                    attrs: {
                                                      id: "imageNewsInput",
                                                      name: "imageNewsInput",
                                                      type: "file",
                                                      accept:
                                                        "image/png, image/jpeg, image/bmp",
                                                    },
                                                    on: {
                                                      change: _vm.setImageNews,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        VCol,
                                        { attrs: { cols: "12", md: "9" } },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "ml-4" },
                                            [
                                              _c(VTextField, {
                                                attrs: {
                                                  label: _vm.$t("title"),
                                                },
                                                model: {
                                                  value: _vm.news.Title,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.news,
                                                      "Title",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "news.Title",
                                                },
                                              }),
                                              _c(VTextField, {
                                                attrs: {
                                                  label: _vm.$t("url"),
                                                  rules: [_vm.required],
                                                },
                                                model: {
                                                  value: _vm.news.Ref,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.news,
                                                      "Ref",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "news.Ref",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCardActions,
                                    [
                                      _c(
                                        VBtn,
                                        {
                                          staticClass: "dxa_modal_btnSuccess",
                                          attrs: {
                                            color: "primary",
                                            "min-width": "100",
                                            loading: _vm.dialog_loading,
                                            type: "submit",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("save")))]
                                      ),
                                      _c(
                                        VBtn,
                                        {
                                          staticClass: "dxa_modal_btnError",
                                          attrs: {
                                            color: "red",
                                            "min-width": "100",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.dialog = false
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("cancel")))]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.error
                                ? _c(
                                    VAlert,
                                    {
                                      staticClass: "ma-2",
                                      attrs: { type: "error" },
                                    },
                                    [_vm._v(_vm._s(_vm.$t(_vm.error)))]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _c(
                            VContainer,
                            [
                              _c(VProgressCircular, {
                                staticStyle: { "margin-left": "50%" },
                                attrs: {
                                  indeterminate: "",
                                  size: "70",
                                  color: "primary",
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.delete_dialog
            ? _c(
                VDialog,
                {
                  model: {
                    value: _vm.delete_dialog,
                    callback: function ($$v) {
                      _vm.delete_dialog = $$v
                    },
                    expression: "delete_dialog",
                  },
                },
                [
                  _c("DeleteConfirmationModal", {
                    attrs: { obj: _vm.delete_obj, name: _vm.delete_obj.Title },
                    on: {
                      close: function ($event) {
                        _vm.delete_dialog = false
                      },
                      delete: _vm.remove_news,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _c(
        VContainer,
        [
          _c(VProgressCircular, {
            staticStyle: { "margin-left": "50%" },
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }